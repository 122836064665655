import React, { useContext, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Title } from "../components/PageElements";
import { Helmet } from "react-helmet";
import { Context } from "../components/Layout/index";
import styled from "styled-components";
import Note from "../components/CmsComponents/Note";
import { getName } from "../helpers/utils";
import { Location } from "@gatsbyjs/reach-router";

const DocHomePageCms = ({ location }) => {
  const { width } = useContext(Context) || {};

  const data = useStaticQuery(graphql`
    query {
      allApi {
        nodes {
          name
        }
      }
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title1
              title2
              title3
              title4
              paragraph1
              paragraph2
              paragraph3
              paragraph4
            }
          }
        }
      }
    }
  `);

  const [apiName] = useState(getName(location.pathname.split("/")[1]) || "");
  const [current] = useState(
    data?.allApi?.nodes.filter((node) => getName(node.name) === apiName) || []
  );

  const content = data.allMarkdownRemark.edges.filter(
    (node) =>
      getName(node.node.frontmatter.title1) === getName(current?.[0]?.name)
  );

  return (
    <Wrapper width={width}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`smsmode© | ${getName(
            location.pathname.split("/")[1]
          ).toUpperCase()} REST API`}
        </title>
      </Helmet>
      {content.map((item) => (
        <Container key={item.node.frontmatter.title1}>
          <Title>{item.node.frontmatter.title1}</Title>
          <p
            dangerouslySetInnerHTML={{
              __html: item.node.frontmatter.paragraph1
            }}
          />
          <Title2>{item.node.frontmatter.title2}</Title2>
          <p
            dangerouslySetInnerHTML={{
              __html: item.node.frontmatter.paragraph2
            }}
          />
          <Title2>{item.node.frontmatter.title3}</Title2>
          <p
            dangerouslySetInnerHTML={{
              __html: item.node.frontmatter.paragraph3
            }}
          />
          <Note anchor={`note_apikey_input_${apiName}`} />
          <Title2 style={{ marginTop: "40px" }}>
            {item.node.frontmatter.title4}
          </Title2>
          <p
            dangerouslySetInnerHTML={{
              __html: item.node.frontmatter.paragraph4
            }}
          />
          <Note anchor={`note_url_encoding_${apiName}`} />
        </Container>
      ))}
    </Wrapper>
  );
};

const Title2 = styled.h2`
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  color: #52b474;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
`;

const Container = styled.div`
  max-width: 730px;
  width: 100%;

  z-index: 1;
  & > p {
    font-weight: 400;
    margin: 10px -10px 50px -10px;
    font-size: 14px;
    color: #bdbdbd;
    line-height: 25px;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.body};
    border-radius: 5px;
    padding: 10px;
    transition: ease 0.275s;
  }
  & > p > span {
    font-weight: 900;
  }
  & > p > a {
    color: #52b474;
  }
  & > p > .italic {
    font-style: italic;
    font-weight: normal;
  }
  padding-bottom: 30px;

  @media (max-width: 780px) {
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.small} {
    align-items: stretch;
  }
  @media (max-width: 1200px) {
    padding-left: 0px;
    align-items: center;
  }
  @media ${({ theme }) => theme.breakpoints.large} {
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <DocHomePageCms {...locationProps} {...props} />}
  </Location>
);
